<template>
  <div class="bg-slate-100/80 h-screen">
    <div class="max-w-[1400px] mx-auto bg-white transition-transform" ref="main" :style="drawerAdjustStyle">

      <nuxt-loading-indicator />
      <div class="flex flex-col h-screen">
        <header class="bg-white flex items-center px-4 py-6 gap-3 bg-gradient-to-b from-ice to-white">
          <nuxt-link class="block flex-1" to="/" external>
            <img alt="FOREVER® Logo" src="https://forever-mars-ui.s3.amazonaws.com/images/forever-logo-v2.svg" class="h-9" />
          </nuxt-link>
          <div class="flex items-center gap-3">
            <span class="hidden sm:inline">Need an account?</span>
            <u-button variant="link" size="xl" :padded="false" :to="EXTERNAL_URLS.signUp" external>
              <span>Sign up<span class="hidden sm:inline"> for free</span></span>
            </u-button>
            <u-button
              :icon="COMMON_ICONS.signIn"
              :to="EXTERNAL_URLS.signIn"
              external
              class="no-underline"
              label="Login"
            />
          </div>
        </header>

        <div class="flex-1 flex gap-4 overflow-hidden p-4">

          <!-- todo: fix these transport targets -->
          <!-- toolbar: selection -->
          <!--          <layout-selection-toolbar />-->

          <!--column: contextual drawer-->
          <div
            ref="contextNav"
            class="-mt-4 w-72 min-w-72 transition-all ease-in-out delay-200 fixed hidden md:block z-10 md:relative bg-white h-full"
            v-if="showAlbumsNav"
          >
            <transition name="f-fade">
              <div class="absolute z-10 inset-0 bg-white" v-if="showAlbumsNav">
                <album-navigation />
              </div>
            </transition>
          </div>

          <nuxt-page class="h-full flex-1" />
        </div>
      </div>

    </div>

    <u-notifications>
      <template #title="{title}"><span v-html="title" /></template>
      <template #description="{description}"><span v-html="description" /></template>
    </u-notifications>
    <core-loading />
    <core-modal-modals />
    <core-offline-screen />
  </div>

</template>

<script setup>
  const main = ref();
  const {showAlbumsNav} = useAlbumsNav();

  //DRAWER ADJUST
  const drawerAdjust = ref(0);
  useEventBus('drawer-adjust-main').on(payload => {
    drawerAdjust.value = payload.width;
  })
  const drawerAdjustStyle = computed(() => {
    if (drawerAdjust.value && process.client) {
      const offsetWidth = main.value.offsetLeft;
      if (drawerAdjust.value > offsetWidth) {
        return {
          transform: `translateX(${drawerAdjust.value - offsetWidth}px)`
        }
      }
    }
  });
</script>
